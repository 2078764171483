import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../components/SimpleLocalize";


export const LaConsulta = (props) => {
    const { pageContext } = props;
    let camino = '../';
    if(typeof window !== 'undefined'){
        camino = window.location.origin
    }
    let caminoimg = '..'
	let poslang = '';
    if(pageContext.language == 'en'){
        caminoimg = '../..';
		poslang = "_en";
    }
    const items = [{ img: `${caminoimg}/suelos/perfilsuelo_LC${poslang}.jpg`, label: pageContext.messages["calicata"] }, 
    { img: `${camino}/tour/calicatas.html?startscene=1&lang=${pageContext.language}`, label: pageContext.messages["vista360"] }, 
    { img: `${caminoimg}/suelos/mapa_suelo_zonas_LC${poslang}.jpg`, label: pageContext.messages["ubicacion"] }]
    const [activeIndex, setActiveIndex] = useState(0);

    const handleOnClick = (index) => {
        setActiveIndex(index);
    }

    return (
        <SimpleLocalize {...props}>
        <section className="py-0 text-center position-relative min-vh-100 tipo-suelo">
            <div className="bg-holder bg-white no-top">
                <div className="container min-vh-100 content-wrapper">
                    <div className="row">
                        <div className="col-md-4 text-start pt-3 texto-con-scroll">
                            <div>
                                <h1 className="title-2 mt-3">La Consulta</h1>
                                <hr />
                                <nav className="navbar-expand-lg navbar-light">
                                    <div className="navbar-nav">
                                        {items.map((item, index) => {
                                            return (<a className={activeIndex == index ? "nav-item nav-link active" : "nav-item nav-link unactive"} onClick={() => handleOnClick(index)}>{item.label}</a>)
                                        })}
                                    </div>
                                </nav>
                                <p>
                                    <FormattedMessage id="suelo-franco-arenoso" defaultMessage="suelo-franco-arenoso" />.<br/>
                                    <FormattedMessage id="semi-profundo" defaultMessage="semi-profundo" />.<br/>
                                    <FormattedMessage id="presencia-de-rocas-medianas" defaultMessage="presencia-de-rocas-medianas" />.
                                </p>
                            </div>
                            <div className="ficha mt-2 p-4">
                                <h4><FormattedMessage id="origen-y-formacion" defaultMessage="origen-y-formacion" /></h4>
                                <p><FormattedMessage id="fluvial-aluvional" defaultMessage="fluvial-aluvional" /></p>
                                <h4><FormattedMessage id="pendiente" defaultMessage="pendiente" /></h4>
                                <p><FormattedMessage id="1-a-3-por-ciento" defaultMessage="1-a-3-por-ciento" /></p>
                            </div>
                        </div>
                        <div className="min-vh-100 col-md-8 img_height">
                            {items.map((item, index) => {
                                return (item.label.search('360') === -1 ? <img className={activeIndex == index ? "d-block" : "d-none"} src={item.img} /> :
                                    <iframe className={activeIndex == index ? "d-block" : "d-none"} style={{ "width": "100%", "height": "100%" }} src={item.img} />)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </SimpleLocalize>
    );
};
