import React, { useEffect, useState } from 'react';
import SimpleLocalize from "../SimpleLocalize";
import { FormattedMessage } from "react-intl";

export const PerfilAndes = (props) => {
    const { pageContext } = props;
    let camino = '..'
    if (props.pageContext.language == 'en') {
        camino = '../..'
    }
    const [porcentajeDeReduccion, setPorcentajeDeReduccion] = useState(null);
    const [margenSuperion, setMargenSuperior] = useState(null);
    const [itemSeleccionado, setItemSeleccionado] = useState('')
    const calc = () => {
        const margenDerechoScroll = 0;
        const ventanaWidth = window.innerWidth - margenDerechoScroll
        const imagenPrincipal = document.querySelector("#main-image");
        let redux = null
        if (imagenPrincipal) {
            redux = ((ventanaWidth / imagenPrincipal.naturalWidth) * 100) / 100
        }
        setPorcentajeDeReduccion(redux);

        var alturaFinal = (imagenPrincipal.height * (ventanaWidth / imagenPrincipal.naturalWidth) * 100) / 100
        let redux2 = (document.querySelector("#perfil-andes").clientHeight - (imagenPrincipal.naturalHeight * redux))
        setMargenSuperior(redux2)
    }
    if (typeof window !== 'undefined') {
        window.addEventListener('resize', calc);
    }
    const parallax = (event) => {
        document.querySelectorAll(".parallax-wrap div").forEach((shift) => {
            const position = shift.dataset.value;
            const x = (window.innerWidth - event.pageX * position) / 90;
            const y = (window.innerHeight - event.pageY * position) / 90;

            shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
        });
    }
    if (typeof document !== `undefined`) {
        document.addEventListener("mousemove", parallax);
    }
    const menu = [
        { id: "item_1", left: "1785px", width: "167px", height: "745px", src: camino + "/perfilandes/suelo-01-precordillera.jpg", imgtxtsrc: camino + "/perfilandes/texto-01-precordillera.png", leftText: "1960px", topText: "30px", text: "Pre-Cordillera:", subtext: pageContext.messages["sedimentos-coluviales"], marginText: "600px" },
        { id: "item_2", left: "1952px", width: "167px", height: "745px", src: camino + "/perfilandes/suelo-02-parajealtamira.jpg", imgtxtsrc: camino + "/perfilandes/texto-02-parajealtamira.png", leftText: "2130px", topText: "80px", text: "Paraje Altamira:", subtext: `<strong>${pageContext.messages["origen-y-formacion"]}</strong><br/>${pageContext.messages["fluvial-aluvional"]}`, marginText: "430px" },
        { id: "item_3", left: "2117px", width: "167px", height: "745px", src: camino + "/perfilandes/suelo-03-laconsulta.jpg", imgtxtsrc: camino + "/perfilandes/texto-03-laconsulta.png", leftText: "2280px", topText: "160px", text: "La Consulta:", subtext: `<strong>${pageContext.messages["origen-y-formacion"]}</strong><br/>${pageContext.messages["fluvial-aluvional"]}`, marginText: "260px" },
        { id: "item_4", left: "2282px", width: "167px", height: "745px", src: camino + "/perfilandes/suelo-04-eugeniobustos.jpg", imgtxtsrc: camino + "/perfilandes/texto-04-eugeniobustos.png", leftText: "2440px", topText: "250px", text: "Eugenio Bustos:", subtext: `<strong>${pageContext.messages["origen-y-formacion"]}</strong><br/>${pageContext.messages["fluvial-aluvional"]}`, marginText: "95px" }
    ]

    const vientos = [
        { id: "01", posx: "80px", posy: "450px", value: "-15" },
        { id: "02", posx: "500px", posy: "380px", value: "5" },
        { id: "03", posx: "1200px", posy: "300px", value: "10" },
        { id: "04", posx: "1220px", posy: "320px", value: "-5" },
        { id: "05", posx: "1600px", posy: "450px", value: "5" },
    ]

    const nubes = [
        { id: "01", posx: "130px", posy: "180px", value: "-10" },
        { id: "02", posx: "800px", posy: "200px", value: "5" },
        { id: "03", posx: "2200px", posy: "300px", value: "10" },
        { id: "04", posx: "2320px", posy: "420px", value: "-5" },
    ]
    useEffect(() => {
        calc();
    }, [])
    return (
        <SimpleLocalize {...props}>
            <section className="py-0 text-center position-relative min-vh-100">
                <div className="bg-holder bg-white no-top d-flex align-items-center" id="perfil-andes">
                    <div className="container content-wrapper">
                        <div className="row">
                            <div className="col-md-12 text-start pt-3 texto-con-scroll">
                                <h1 className="title-2 mt-3"><FormattedMessage id="cordillera-de-los-andes" defaultMessage="cordillera-de-los-andes" /></h1>
                                <hr style={{"width":"300px"}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{ "transform": "scale(" + porcentajeDeReduccion + ")", "top": margenSuperion }} className='position-absolute'>
                        {menu.map((item) => {
                            return (<div className='position-absolute' style={{ "left": item.left, "top": "26px", "width": item.width, "height": item.height, "zIndex": 2, "cursor": "pointer" }}
                                onMouseEnter={() => { setItemSeleccionado(item.id) }} onMouseLeave={() => { setItemSeleccionado('') }}></div>)
                        })}
                        <img src={camino + "/perfilandes/perfil-andes.png"} alt="" className='position-absolute top-0' id="main-image" onLoad={calc} style={{ "zIndex": "1" }} />
                        <img src={camino + "/perfilandes/marco-suelos.jpg"} alt="" className='position-absolute' style={{ "left": "1784px", "top": "26px" }} />
                        {menu.map((item) => {
                            return (
                                <div className={itemSeleccionado == item.id ? 'opacity-1 position-absolute' : 'opacity-0 position-absolute'} style={{ "left": item.leftText, "top": item.topText }}>
                                    <img src={item.imgtxtsrc} alt="" />
                                    <div className='position-absolute top-0 text-start text-perfil-andes-wrapper' style={{ "left": item.marginText }}>
                                        <div className='main-text'>{item.text}</div><span className='subtext' dangerouslySetInnerHTML={{ __html: item.subtext }}></span>
                                    </div>
                                </div>
                            )
                        })}
                        {menu.map((item) => {
                            return (<img src={item.src} alt="" className={itemSeleccionado == item.id ? 'opacity-1 position-absolute' : 'opacity-0 position-absolute'} style={{ "left": item.left, "top": "26px" }} />)
                        })}
                        <div class="parallax-wrap">
                            {vientos.map((viento) => {
                                return (<div className='position-absolute' style={{ "left": viento.posx, "top": viento.posy }} data-value={viento.value}>
                                    <img src={`${camino}/perfilandes/viento-${viento.id}.png`} alt="" />
                                </div>)
                            })}
                            {nubes.map((nube) => {
                                return (<div className='position-absolute' style={{ "left": nube.posx, "top": nube.posy }} data-value={nube.value}>
                                    <img src={`${camino}/perfilandes/nube-${nube.id}.png`} alt="" />
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </SimpleLocalize>
    );
};
